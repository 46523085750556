import React from "react";
import { Link } from "react-router-dom";
import { WEBSITE_URL, openNewPage } from "../modals/Constants";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "../../../node_modules/@splidejs/react-splide/dist/css/splide.min.css";

function SlideShow({ dataList }) {
  return (
    <Splide
      options={{
        type: "loop",
        autoplay: true,
        perPage: 1,
        pagination: false,
        arrows: false,
        rewind: true,
      }}
    >
      {dataList.map((data, index) => (
        <SplideSlide key={index}>
          <Link
            className="col-view ovf-hidden bg-white"
            onClick={() =>
              data["slider_action"] != "none"
                ? openNewPage(data["slider_action"])
                : ""
            }
          >
            <div className="imageContainer">
              <img
                className="w-100 br-8 obj-f-contain"
                src={data["slider_img"]}
                alt="image"
              />
            </div>
          </Link>
        </SplideSlide>
      ))}
    </Splide>
  );
}

export default SlideShow;
