import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog/toast';
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import '../../MainStyle.css';
import { API_ACCESS_URL } from '../modals/Constants';
import { getStorage } from '../modals/Storage';
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function ChangePassword() {
  const navigate = useNavigate();
  const toastDialogRef = useRef(null);
  const [showLoading, setShowLoading] = useState(false);

  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [pageConst, setConstants] = useState({
    pageTitle: "Change Password",
    inNewPassword: "",
    inPassword: "",
  });

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  }

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const checkForInputVal = (newpassword, password) => {
    if (newpassword != "" && newpassword != undefined && password != "" && password != undefined) {
      if (newpassword == password && newpassword.length >= 6) {
        setInValCorrect(true);
      } else {
        setInValCorrect(false);
      }
    } else {
      setInValCorrect(false);
    }
  }

  const onInputValChange = (source, data) => {

    if (source == "newpassword") {
      checkForInputVal(data, pageConst.inPassword);

      setConstants(previousState => {
        return { ...previousState, inNewPassword: data }
      });
    }

    if (source == "password") {
      checkForInputVal(pageConst.inNewPassword, data);

      setConstants(previousState => {
        return { ...previousState, inPassword: data }
      });
    }
  }

  const validateChangePassword = () => {
    const requestAPI = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-change-password',
            'AuthToken': getStorage("secret"),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "authorization_error") {
          showToast("Authorization Error!");
        } else if (data.status_code == "password_error") {
          showToast("Password not correct! try again");
        } else if (data.status_code == "success") {
          showToast("Password Changed!", 2500, 0, 1);
        } else {
          showToast("Something went wrong! Please try again!");
        }

      } catch (error) {
        updateLoadingStatus(false);
        showToast("There was a technical issue! Please try again!");
      }
    };

    if (isInputValCorrect) {
      updateLoadingStatus(true);
      const formData = { USER_ID: getStorage("uid"), NEW_PASSWORD: pageConst.inPassword };
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate('/LG', { replace: true });
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.appContentsBackground,
        ].join(" ")}
      >
        <LoadingDialog intentData={showLoading} />
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />

        <div className="pr-v ovf-scrl-y hide-sb zoomAnimView">
          <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" />

          <div className='col-view w-100 v-center pd-5-15 mg-b-15 mg-t-45'>

            <div
              className={[
                styles.inputView,
                general.colView,
                general.width100,
                general.mgT15px,
              ].join(" ")}
            >
              <input
                type="text"
                className={[
                  general.inputBox,
                  general.height50px,
                  general.bgWhite,
                  general.borderRadius10px,
                ].join(" ")}
                placeholder="New Password"
                autoComplete="new-password"
                onChange={(e) => onInputValChange("newpassword", e.target.value)}
              ></input>
            </div>

            <div
              className={[
                styles.inputView,
                general.colView,
                general.width100,
                general.mgT10px,
              ].join(" ")}
            >
              <input
                type="password"
                className={[
                  general.inputBox,
                  general.height50px,
                  general.bgWhite,
                  general.borderRadius10px,
                ].join(" ")}
                placeholder="Repeat Password (≥6 characters)"
                autoComplete="new-password"
                onChange={(e) => onInputValChange("password", e.target.value)}
              ></input>
            </div>

            <div className={[
              general.height50px,
              general.width100,
              general.viewCenter,
              general.colorWhite,
              general.fontS20Px,
              general.mgT20px,
              general.borderRadius,
              general.bgCustomColor2,
            ].join(" ")}
              onClick={() => validateChangePassword()}>
              <span>Change Password</span>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

export default ChangePassword;